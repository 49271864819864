import gql from 'graphql-tag'
import { expeditionDetailFields } from './expeditionGetQuery'

const expeditionUpdateQuery = gql`
  query expeditionUpdate(
    $id: String!
    $eshop: String!
    $warehouse: String!
    $wms: String!
    $orderNumber: String!
    $note: String
    $billingFirstName: String!
    $billingLastName: String!
    $billingDegree: String
    $billingCompany: String
    $billingStreet: String!
    $billingHouseNr: String!
    $billingZip: String!
    $billingCity: String!
    $billingCountry: String!
    $billingState: String!
    $billingEmail: String!
    $billingPhone: String!
    $billingRegistrationNumber: String
    $billingVatNumber: String
    $differentDeliveryAddress: Boolean
    $deliveryFirstName: String
    $deliveryLastName: String
    $deliveryDegree: String
    $deliveryCompany: String
    $deliveryStreet: String
    $deliveryState: String
    $deliveryHouseNr: String
    $deliveryZip: String
    $deliveryCity: String
    $deliveryCountry: String
    $deliveryEmail: String
    $deliveryPhone: String
    $requiredExpeditionDate: String!
    $carrier: String!
    $carrierService: String!
    $carrierPickupPlace: String
    $externalCarrierPickupPlace: String
    $carrierNote: String
    $trackingNumber: String
    $trackingUrl: String
    $externalTrackingNumber: String
    $partner: String
    $packagesCount: Number
    $status: String!
    $value: Number!
    $currency: String!
    $b2b: Boolean
    $fragile: Boolean
    $cod: Boolean
    $codValue: Number
    $codCurrency: String
    $codVariableSymbol: String
    $customerGroup: String
    $sentAt: String
    $packedAt: String
    $deliveredAt: String
    $eshopOrderDate: String
    $createdAt: String
    $changedAt: String
    $items: [String]
    $invoiceUrl: String
    $ref1: String
    $ref2: String
    $ref3: String
    $ignoreAddressValidation: Boolean
    $carrierOptions: String
    $deliveryCost: Number
    $deliveryCostCurrency: String
    $invoiceNumber: String
    $services: [String]
  ) {
    expeditionUpdate(
      id: $id
      input: {
        eshop: $eshop
        warehouse: $warehouse
        wms: $wms
        orderNumber: $orderNumber
        note: $note
        billingFirstName: $billingFirstName
        billingLastName: $billingLastName
        billingDegree: $billingDegree
        billingCompany: $billingCompany
        billingStreet: $billingStreet
        billingHouseNr: $billingHouseNr
        billingZip: $billingZip
        billingCity: $billingCity
        billingState: $billingState
        billingCountry: $billingCountry
        billingEmail: $billingEmail
        billingPhone: $billingPhone
        billingRegistrationNumber: $billingRegistrationNumber
        billingVatNumber: $billingVatNumber
        differentDeliveryAddress: $differentDeliveryAddress
        deliveryFirstName: $deliveryFirstName
        deliveryLastName: $deliveryLastName
        deliveryDegree: $deliveryDegree
        deliveryCompany: $deliveryCompany
        deliveryStreet: $deliveryStreet
        deliveryState: $deliveryState
        deliveryHouseNr: $deliveryHouseNr
        deliveryZip: $deliveryZip
        deliveryCity: $deliveryCity
        deliveryCountry: $deliveryCountry
        deliveryEmail: $deliveryEmail
        deliveryPhone: $deliveryPhone
        requiredExpeditionDate: $requiredExpeditionDate
        carrier: $carrier
        carrierService: $carrierService
        carrierPickupPlace: $carrierPickupPlace
        externalCarrierPickupPlace: $externalCarrierPickupPlace
        carrierNote: $carrierNote
        trackingNumber: $trackingNumber
        trackingUrl: $trackingUrl
        externalTrackingNumber: $externalTrackingNumber
        partner: $partner
        packagesCount: $packagesCount
        status: $status
        value: $value
        currency: $currency
        b2b: $b2b
        fragile: $fragile
        cod: $cod
        codValue: $codValue
        codCurrency: $codCurrency
        codVariableSymbol: $codVariableSymbol
        customerGroup: $customerGroup
        sentAt: $sentAt
        packedAt: $packedAt
        deliveredAt: $deliveredAt
        eshopOrderDate: $eshopOrderDate
        items: $items
        ref1: $ref1
        ref2: $ref2
        ref3: $ref3
        ignoreAddressValidation: $ignoreAddressValidation
        carrierOptions: $carrierOptions
        deliveryCost: $deliveryCost
        deliveryCostCurrency: $deliveryCostCurrency
        invoiceNumber: $invoiceNumber
        services: $services
      }
    ) @rest(type: "Expedition", method: "PUT", path: "expedition/{args.id}") {
      ${expeditionDetailFields}
    }
  }
`

export default expeditionUpdateQuery
